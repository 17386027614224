<template>
  <div class="home-wrapper--locations">
    <el-container class="wrapper-locations">
      <el-main>
        <div class="container-locations">
          <div class="wrapper-section-header">
            <h2 class="home-section-title">{{ $t(`${base}.title`) }}</h2>
            <p class="home-section-description">{{
              $t(`${base}.description`)
            }}</p>
          </div>

          <LazyLoading
            :key="`locations`"
            :loading="locations.length === 0"
            :loaded="lazy.locations"
            @loaded="(e) => (lazy.locations = e)"
          >
            <Swiper
              v-if="lazy.locations"
              ref="swiperLocations"
              :options="swiperOption"
            >
              <SwiperSlide
                v-for="(post, i) in locations"
                :key="i"
                :class="[`swiper-slide-${i + 1}`]"
              >
                <LadCard
                  :item="post"
                  :base="base"
                  :to="{
                    name: 'search-type',
                    params: {
                      type: $t('router.search_type.type.city'),
                      value: post.slug,
                      tab: $t('router.search_type.tab.tours'),
                    },
                  }"
                />
              </SwiperSlide>

              <div slot="button-prev" class="swiper-button-prev"></div>
              <div slot="button-next" class="swiper-button-next"></div>
            </Swiper>

            <template slot="css-skeleton">
              <LadCitySwiperLoader />
            </template>
          </LazyLoading>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CacheComponentMixin from '@/utils/mixin/cache-components'

export default {
  name: 'LadHomeLocations',
  components: {
    LadCard: () =>
      import(/* webpackChunkName: "lad-card" */ '@/components/card/index'),
    LadCitySwiperLoader: () =>
      import(
        /* webpackChunkName: "lad-city-swiper-loader" */ '@/components/css-skeleton/city-swiper-loader'
      ),
  },
  mixins: [CacheComponentMixin],
  data() {
    return {
      base: 'home.locations',
      locations: [],
      current: 0,
      lazy: { locations: false },
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 15,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
          },
          // when window width is >= 800px
          800: {
            slidesPerView: 4,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange: () => {
            const swiper = this.$refs.swiperLocations.$swiper

            if (!!swiper && swiper.activeIndex && !isNaN(swiper.activeIndex)) {
              this.current = swiper.activeIndex % this.locations.length
            }
          },
        },
      },
    }
  },
  watch: {
    'lazy.locations': {
      immediate: true,
      handler(newValue, oldValue) {
        if (process.server) return

        if (newValue) {
          this.getData()
        }
      },
    },
  },
  methods: {
    async getData() {
      try {
        const { data, status } = await this.$axios.get(`/api/locations/top`)

        if (status === 'success') {
          const list = []
          data.forEach((item) => {
            list.push({
              type: 'location',
              name: item.name,
              slug: item.slug,
              title: this.$tc(`${this.base}.adventures`, item.experiences, {
                total: item.experiences,
              }),
              description: item.description,
              media: {
                type: 'image',
                data: {
                  url: item.media.url,
                  srcset: item.media.srcset,
                },
              },
            })
          })
          this.locations = list
        }
      } catch (error) {}
    },
  },
}
</script>

<style lang="scss">
.home-wrapper--locations {
  background-color: #ebeef2;

  .container-locations {
    text-align: center;
    text-transform: uppercase;
  }

  .swiper-wrapper {
    padding: 5em 0;

    $transform-list: -1.98729rem 2.57458rem 4.94281rem 1.98094rem -0.94281rem 0.98094rem -1.98729rem
      3.97458rem 2.44281rem 0.98094rem;

    @each $current in $transform-list {
      $i: index($transform-list, $current);

      .swiper-slide-#{$i} {
        transform: translateY($current);
      }
    }
  }

  .swiper-pagination {
    position: relative;
    margin-top: 20px;
    bottom: inherit;
  }

  @media screen and (max-width: 800px) {
    .swiper-wrapper {
      padding: 0;

      @for $i from 1 through 10 {
        .swiper-slide-#{$i} {
          transform: none;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .swiper-wrapper {
      .swiper-slide {
        width: calc(100% - 100px);
        min-width: 220px;
      }
    }
  }
}
</style>
