var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-wrapper--locations"},[_c('el-container',{staticClass:"wrapper-locations"},[_c('el-main',[_c('div',{staticClass:"container-locations"},[_c('div',{staticClass:"wrapper-section-header"},[_c('h2',{staticClass:"home-section-title"},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.title`)))]),_vm._v(" "),_c('p',{staticClass:"home-section-description"},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.description`)))])]),_vm._v(" "),_c('LazyLoading',{key:`locations`,attrs:{"loading":_vm.locations.length === 0,"loaded":_vm.lazy.locations},on:{"loaded":(e) => (_vm.lazy.locations = e)}},[(_vm.lazy.locations)?_c('Swiper',{ref:"swiperLocations",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.locations),function(post,i){return _c('SwiperSlide',{key:i,class:[`swiper-slide-${i + 1}`]},[_c('LadCard',{attrs:{"item":post,"base":_vm.base,"to":{
                  name: 'search-type',
                  params: {
                    type: _vm.$t('router.search_type.type.city'),
                    value: post.slug,
                    tab: _vm.$t('router.search_type.tab.tours'),
                  },
                }}})],1)}),_vm._v(" "),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_vm._v(" "),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2):_vm._e(),_vm._v(" "),_c('template',{slot:"css-skeleton"},[_c('LadCitySwiperLoader')],1)],2)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }